@keyframes _show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes _hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes _fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(0.7);
    opacity: 0;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.login_wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  animation: _show 1s;
  background-position: center center,center center;
  background-size: cover,cover;
  background-image: url('../../images/azure_background.svg');
}
@media (max-width: 600px), (max-height: 366px) {
  .login_wrap {
    display: none;
  }
}
