/* redefine the variables here */
body {
  background: #F6F8FC !important;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-105 {
  margin-bottom: 105px;
}
