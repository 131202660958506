/* redefine the variables here */
.layout {
  margin-bottom: -170px;
}
.navbar {
  z-index: 4;
  width: 100%;
  pointer-events: none;
}
.navbar .container {
  min-height: 88px;
}
.navbar .container a,
.navbar .container button {
  pointer-events: all;
}
.navbar .container .logo_text {
  color: rgba(246, 246, 249, 0.65);
  padding-left: 17px;
  font-size: 14px;
  font-weight: bold;
}
@media (min-width: 1400px) {
  .navbar .container,
  .navbar .container-lg,
  .navbar .container-md,
  .navbar .container-sm,
  .navbar .container-xl,
  .navbar .container-xxl {
    max-width: calc(100% - 120px);
  }
}
