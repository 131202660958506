/* redefine the variables here */
.wrap {
  display: flex;
  height: 100vh;
}
.wrap .container_for_3d {
  margin-bottom: -100px;
  max-width: 100vw !important;
  padding: 0 !important;
}
.wrap .container_for_3d .canvas {
  overflow: unset !important;
}
.wrap .container_for_3d .container_md {
  z-index: 9;
  position: absolute;
  left: 50%;
  top: 145px;
  transform: translate(-50%, 0);
  pointer-events: none;
}
@media (min-width: 1400px) {
  .wrap .container_for_3d .container_md {
    max-width: calc(100% - 120px) !important;
  }
}
.wrap .container_for_3d .viewer360 > div:nth-child(2) {
  top: 330px;
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: calc(100% - 120px) !important;
  }
}
@media (min-width: 1700px) {
  .container_md {
    max-width: 1600px !important;
  }
}
